import { AutosuggestProps } from "@amzn/awsui-components-react";
import { DropdownStatusProps } from "@amzn/awsui-components-react/polaris/internal/components/dropdown-status";
import { OptionDefinition } from "@amzn/awsui-components-react/polaris/internal/components/option/interfaces";
import { debounce } from "common/utils/debounce";
import { InventoryProps, useGetInventory } from "hooks";
import { useCallback, useEffect, useState } from "react";

export interface useCreateOrderFormProps {
    submitPreviewOrder: (itemId: string, requester: string) => Promise<void>;
}

export interface useCreateOrderFormResult {
    state: useCreateOrderFormState;
    actions: useCreateOrderFormActions;
}

export interface useCreateOrderFormState {
    itemSelected: OptionDefinition | null;
    requesterField: string;
    itemSelectedErrorText: string | null;
    requesterFieldErrorText: string | null;
    options: AutosuggestProps.Options | undefined;
    statusType: DropdownStatusProps.StatusType | undefined;
}

export interface useCreateOrderFormActions {
    setItemSelected: React.Dispatch<React.SetStateAction<OptionDefinition | null>>;
    setRequesterField: React.Dispatch<React.SetStateAction<string>>;
    handleLoadItems: (args: string) => Promise<never[]>;
    onClickPreview: () => void;
}

/**
 * Contains all the logic for the form that collects data needed to create an order
 * @returns
 */
export function useCreateOrderForm({
    submitPreviewOrder: submitPreviewOrders,
}: useCreateOrderFormProps): useCreateOrderFormResult {
    const [options, setOptions] = useState<AutosuggestProps.Options>();
    const [itemSelectedErrorText, setItemSelectedErrorText] = useState<string | null>(null);
    const [requesterFieldErrorText, setRequesterFieldErrorText] = useState<string | null>(null);

    const [requesterField, setRequesterField] = useState<string>("");
    const [itemSelected, setItemSelected] = useState<OptionDefinition | null>(null);

    // By default the select will fetch the data on render
    const [statusType, setStatusType] = useState<DropdownStatusProps.StatusType>("loading");

    const inventoryProps: InventoryProps = { pageSize: 5, pageIndex: 1 };
    const useGetInventoryHook = useGetInventory(inventoryProps, undefined);
    const [data, doGetInventory] = [useGetInventoryHook[0], useGetInventoryHook[3]];

    const checkIsValid = () => {
        setItemSelectedErrorText("");
        setRequesterFieldErrorText("");

        let isValid = true;
        if (itemSelected == null) {
            isValid = false;
            setItemSelectedErrorText("You must select an item");
        }

        if (requesterField.trim() == "") {
            isValid = false;
            setRequesterFieldErrorText("You must type an username");
        }

        return isValid;
    };

    const onClickPreview = async () => {
        if (!checkIsValid()) {
            return;
        }
        await submitPreviewOrders(itemSelected!.value!, requesterField);
    };

    const fetchItems = async (filteringText: string) => {
        setStatusType("loading");
        const searchProps = { ...inventoryProps, filters: { productName: [filteringText] } };
        doGetInventory(searchProps);
        return [];
    };

    // Using debounce to prevent calls on every char change
    const handleLoadItems = useCallback(debounce(fetchItems, 750)[0], []);

    useEffect(() => {
        if (!data) {
            setStatusType("finished");
            return;
        }
        const options: AutosuggestProps.Options = data!.items!.map((item) => {
            return { value: item.productTaxonomyId, label: item.productName, description: item.productSku };
        });
        setOptions(options);
        setStatusType("finished");
    }, [data]);

    return {
        actions: {
            onClickPreview,
            setItemSelected,
            setRequesterField,
            handleLoadItems,
        },
        state: { itemSelected, options, requesterField, statusType, itemSelectedErrorText, requesterFieldErrorText },
    };
}
